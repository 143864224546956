import {Leaderboard} from "../components/shareable/leaderboard";
import React from "react";
import {useBetween} from "use-between";
import {useCustomCursorState} from "../components/shareable/custom-cursor";

export interface SelectOption {
    value: string;
    display_text: string;
}

export function buildOptionsFromLeaderboards(leaderboards: Leaderboard[]): SelectOption[] {
    let options: SelectOption[] = []
    for (let i = 0; i < leaderboards.length; i++) {
        options.push({
            value: i.toString(),
            display_text: leaderboards[i].name
        })
    }

    return options
}


export function capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const refineUsername = (username: string): string => {
    return username.replaceAll("@shopee.com", "")
}

export const OnMouseEnter = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const {setIsHovered} = useBetween(useCustomCursorState)
    setIsHovered(true)
}
export const OnMouseLeave = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const {setIsHovered} = useBetween(useCustomCursorState)
    setIsHovered(false)
}