import React, {useState} from "react";


export interface UserProfile {
    id: number;
    username: string;
    display_name: string;
    avatar: string;
    group_id: number;
    remaining_coins: number;
    received_coins: number;
}

export const useUserProfiles = () => {
    const [userProfiles, setUserProfiles] = useState<UserProfile[]>([]);
    const [myUserProfile, setMyUserProfile] = useState<UserProfile>({
        remaining_coins: 0,
        username: '',
        avatar: '',
        display_name: '',
        id: 0,
        group_id: 0,
        received_coins: 0,
    });
    const [viewingUserProfile, setViewingUserProfile] = useState<UserProfile>({
        remaining_coins: 0,
        username: '',
        avatar: '',
        display_name: '',
        id: 0,
        group_id: 0,
        received_coins: 0,
    });
    return {
        userProfiles,
        setUserProfiles,
        myUserProfile,
        setMyUserProfile,
        viewingUserProfile,
        setViewingUserProfile
    }
};