import React from 'react';
import {Box} from "@mui/material";
import {BASE_PATH} from "../../setting/setting";

interface TotalCreditBoxProps {
    totalCredit: number;
}

const TotalCreditBox: React.FC<TotalCreditBoxProps> = ({totalCredit = 0}) => {
    return (
        <>
            <Box sx={{
                border: "2px solid black",
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                boxShadow: '3px 3px 0px 0px rgba(0, 0, 0, 0.20)',
            }}>
                <Box style={{
                    height: '100%',
                    paddingLeft: '12px',
                    paddingRight: '12px',
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <Box sx={{
                        height: '26px',
                        alignSelf: 'center',
                        marginLeft: '5px',
                    }}>
                        <img src={BASE_PATH + "/images/total-credit-icon.png"} style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                        }}>

                        </img>
                    </Box>
                </Box>
                <Box style={{
                    height: '100%',
                    display: 'flex',
                    flex: 1,
                }}>
                    <span style={{
                        alignSelf: 'center',
                        // fontSize: 40,
                        fontSize: 32,
                    }}>{totalCredit}</span>
                </Box>
            </Box>
        </>
    )
}

export default TotalCreditBox;