// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Remove the spinners for number input */
.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.no-spinner {
    -moz-appearance: textfield; /* For Firefox */
}
`, "",{"version":3,"sources":["webpack://./src/components/kudo-dialog/kudo-dialog-credit-input.css"],"names":[],"mappings":"AAAA,yCAAyC;AACzC;;IAEI,wBAAwB;IACxB,SAAS;AACb;;AAEA;IACI,0BAA0B,EAAE,gBAAgB;AAChD","sourcesContent":["/* Remove the spinners for number input */\n.no-spinner::-webkit-inner-spin-button,\n.no-spinner::-webkit-outer-spin-button {\n    -webkit-appearance: none;\n    margin: 0;\n}\n\n.no-spinner {\n    -moz-appearance: textfield; /* For Firefox */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
