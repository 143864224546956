import React, {useEffect} from 'react';
import axios from 'axios';
import {useBetween} from "use-between";
import {GroupInfo, useGroupInfo} from "./group-info";
import {useUserProfiles} from "./user-profile";
import {useLeaderboards} from "./leaderboard";
import {useKudoContents} from "./kudo-content";
import {API_URL, UI_URL} from "../../setting/setting";

interface InitShareableProps {
    currentGroupName: string;
}

const InitShareable: React.FC<InitShareableProps> = ({currentGroupName}) => {
    const {setCurrentGroupInfo, setGroupInfos, groupInfos} = useBetween(useGroupInfo);
    const {setLeaderboards} = useBetween(useLeaderboards);
    const {setKudoContents} = useBetween(useKudoContents);
    const {setUserProfiles, setMyUserProfile} = useBetween(useUserProfiles);
    let currentLocalGroupInfo: GroupInfo = {id: 0, name: 'unknown'}
    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (error.response.status === 403 || error.response.status === 401) {
            window.location.href = `${API_URL}/auth/?redirect=${UI_URL}/${currentGroupName}`
        }
        return Promise.reject(error);
    });
    useEffect(() => {
        fetchGroupInfosData(currentGroupName).then(() => {
            fetchAllFrequentlyUpdatedDataPeriodically(5000)
        })
    }, []);
    return (
        <>
        </>
    )

    function fetchAllFrequentlyUpdatedDataPeriodically(timeout: number) {
        fetchMyUserProfile()
        fetchUserProfileData()
        fetchLeaderboards()
        fetchKudoContents()
        loopTimeout(timeout, () => {
            fetchAllFrequentlyUpdatedDataPeriodically(timeout)
        })
    }

    async function fetchUserProfileData() {
        try {
            const response = await axios.get(`${API_URL}/groups/${currentLocalGroupInfo.id}/members/`);
            setUserProfiles(response.data.data);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    }

    async function fetchGroupInfosData(currentGroupName: string) {
        try {
            const response = await axios.get(`${API_URL}/groups/`);
            setGroupInfos(response.data.data)
            for (const group of response.data.data) {
                if (group.name.toLowerCase() === currentGroupName.toLowerCase()) {
                    currentLocalGroupInfo = group
                    setCurrentGroupInfo(currentLocalGroupInfo)
                }
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    }

    async function fetchMyUserProfile() {
        try {
            const response = await axios.get(`${API_URL}/groups/${currentLocalGroupInfo.id}/me/`);
            setMyUserProfile(response.data.data);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    }

    async function fetchLeaderboards() {
        try {
            const response = await axios.get(`${API_URL}/groups/${currentLocalGroupInfo.id}/leaderboards/`);
            setLeaderboards(response.data.data);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    }

    async function fetchKudoContents() {
        try {
            const response = await axios.get(`${API_URL}/groups/${currentLocalGroupInfo.id}/kudos/`);
            setKudoContents(response.data.data);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    }
}

function loopTimeout(timeout: number, func: () => any) {
    setTimeout(func, timeout)
}

export default InitShareable;