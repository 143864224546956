import React from 'react';
import {Box} from "@mui/material";
import {useBetween} from "use-between";
import {useCustomCursorState} from "../shareable/custom-cursor";

interface HoverableBoxProps {
    sx?: any;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    children: React.ReactNode;
    enabled?: boolean;
}

const HoverableBox: React.FC<HoverableBoxProps> = ({sx, children, onClick, enabled = true}) => {
    const {setIsHovered, setIsClicked} = useBetween(useCustomCursorState)
    const onMouseEnter = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setIsHovered(true)
    }
    const onMouseLeave = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setIsHovered(false)
    }
    const onMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setIsClicked(true)
    }

    const onMouseUp = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setIsClicked(false)
    }
    return (
        <>
            <Box
                onMouseEnter={enabled ? onMouseEnter : () => {
                }}
                onMouseLeave={enabled ? onMouseLeave : () => {
                }}
                onMouseDown={enabled ? onMouseDown : () => {
                }}
                onMouseUp={enabled ? onMouseUp : () => {
                }}
                sx={sx}
                onClick={enabled ? onClick : () => {
                }}
            >
                {children}
            </Box>
        </>
    )
}

export default HoverableBox;