import React from 'react';
import {Grid} from "@mui/material";
import {useBetween} from "use-between";
import {useKudoFormState} from "../shareable/kudo-form";
import HashtagBox from "./hashtag-box";

const KudoDialogHashtagInput = () => {
    const {kudoFormState, setKudoFormState} = useBetween(useKudoFormState)
    const hashtags = ["INSPIRER", "TEAM PLAYER", "MENTOR", "IMPROVER", "TAOLAO"]
    let mapHashtag = new Map<string, boolean>()
    const onHashtagBoxClick = (hashtag: string) => {
        mapHashtag.set(hashtag, !mapHashtag.get(hashtag))
        let hashtags: string[] = []
        mapHashtag.forEach((exist: boolean, hashtag: string) => {
            if (exist) {
                hashtags.push(hashtag)
            }
        })
        kudoFormState.hashtags = hashtags
        setKudoFormState(kudoFormState)
    }

    return (
        <>
            <Grid container sx={{
                width: '100%',
            }}>
                <Grid item sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    height: '16px',
                    marginBottom: '8px',
                }}>
                    <p style={{
                        flex: 1,
                        fontSize: 16,
                    }}>HASHTAG</p>
                </Grid>
                <Grid item sx={{
                    display: 'flex',
                    alignItems: 'stretch',
                    width: '100%',
                    gap: '8px',
                    flexWrap: 'wrap',
                }}>
                    {
                        hashtags.map((hashtag) => {
                            return <HashtagBox hashtag={hashtag} onHashtagBoxClick={onHashtagBoxClick}></HashtagBox>
                        })
                    }

                </Grid>
            </Grid>
        </>
    )
}

const isTrue = (value: boolean | undefined) => {
    if (value == undefined) {
        return false
    }

    return value
}

export default KudoDialogHashtagInput;