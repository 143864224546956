import React from 'react';
import {Box, SelectChangeEvent} from "@mui/material";
import LeaderboardTable from "./leaderboard-table";
import SwitchLeaderboardButton from "./switch-leaderboard-button";
import {useBetween} from "use-between";
import {useLeaderboards} from "../shareable/leaderboard";
import {buildOptionsFromLeaderboards} from "../../utils/utils";

const LeaderboardBox = () => {
    const {leaderboards} = useBetween(useLeaderboards)
    const [currentLeaderboardIndex, setCurrentLeaderboardIndex] = React.useState<number>(0)
    const onChangeLeaderboard = (event: SelectChangeEvent) => {
        setCurrentLeaderboardIndex(Number(event.target.value))
    }
    return (
        <>
            <Box sx={{
                paddingX: '26px',
                paddingY: '32px',
            }}>
                <SwitchLeaderboardButton
                    options={buildOptionsFromLeaderboards(leaderboards)}
                    onChangeOption={onChangeLeaderboard}
                ></SwitchLeaderboardButton>
            </Box>
            <Box sx={{
                paddingX: '32px',
            }}>
                <LeaderboardTable
                    currentLeaderboardIndex={currentLeaderboardIndex}
                    leaderboards={leaderboards}
                ></LeaderboardTable>
            </Box>

        </>
    )
}

export default LeaderboardBox;