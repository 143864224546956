import React, {useState} from 'react';
import {Box} from "@mui/material";

interface HashtagBoxProps {
    hashtag: string;
    onHashtagBoxClick: (hashtag: string) => void;
}

const HashtagBox: React.FC<HashtagBoxProps> = ({hashtag, onHashtagBoxClick}) => {
    let [isHashtagSelected, setIsHashtagSelected] = useState<boolean>(false)
    return (
        <>
            <Box sx={{
                display: 'flex',
                height: '22px',
                border: 'solid 2px black',
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: '9px',
                paddingRight: '9px',
                backgroundColor: (isHashtagSelected) ? "#FECA42" : "white",
                fontSize: 14,
                boxShadow: (isHashtagSelected) ? '2px 2px 0px 0px rgba(0, 0, 0, 0.15)' : '',
                wordSpacing: '-2px',
            }}
                 onClick={() => {
                     onHashtagBoxClick(hashtag)
                     setIsHashtagSelected(!isHashtagSelected)
                 }}>
                #{hashtag}
            </Box>
        </>
    )
}

export default HashtagBox;