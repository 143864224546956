import React from 'react';
import {Grid} from "@mui/material";
import {BASE_PATH} from "../../setting/setting";
import HoverableBox from "../cursor/hoverable-box";

interface MyKudoDialogHeaderProps {
    onClick: () => void;
    username: string;
}

const UserKudoDialogHeader: React.FC<MyKudoDialogHeaderProps> = ({onClick, username}) => {
    return (
        <>
            <Grid container sx={{
                width: '100%',
                height: '47px',
                backgroundColor: '#FECA42',
                paddingLeft: '24px',
                paddingRight: '16px',
                borderBottom: 'solid 2px black',
            }}>
                <Grid item sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                }}>
                    <span style={{
                        flex: 1,
                        fontSize: 24,
                        wordSpacing: '-4px',
                    }}>{username.toUpperCase()} PROFILE</span>
                    <HoverableBox sx={{
                        width: '20px',
                        height: '20px',
                        alignSelf: 'center',
                    }}
                                  onClick={() => onClick()}
                    >
                        <img src={BASE_PATH + "/images/form-close-botton.png"} style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                        }}>

                        </img>
                    </HoverableBox>
                </Grid>
            </Grid>
        </>
    )
}

export default UserKudoDialogHeader;