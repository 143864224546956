import * as React from 'react';
import {useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {Box, SelectChangeEvent} from "@mui/material";
import KudoHistoryBox from "../kudo-timeline/kudo-history-box";
import SwitchKudoHistoryButton from "./switch-kudo-history-button";
import {SelectOption} from "../../utils/utils";
import {useBetween} from "use-between";
import {MyKudoContentByRange} from "../shareable/kudo-content";
import UserKudoDialogHeader from "./user-kudo-dialog-header";
import {useMyKudoDialog} from "../shareable/my-kudo-dialog";
import axios from "axios";
import {UserProfile, useUserProfiles} from "../shareable/user-profile";
import {API_URL} from "../../setting/setting";

interface UserKudoDialogProps {
    userProfile: UserProfile;
}

const UserKudoDialog: React.FC<UserKudoDialogProps> = ({userProfile}) => {
    const {viewingUserProfile} = useBetween(useUserProfiles)
    const [currentKudoRangeIndex, setCurrentKudoRangeIndex] = React.useState<number>(0)
    const {isMyKudoDialogOpen, setMyKudoDialogOpen} = useBetween(useMyKudoDialog)
    const [currentUserKudoContents, setCurrentUserKudoContents] = useState<MyKudoContentByRange[]>([]);

    const onChangeKudoRange = (event: SelectChangeEvent) => {
        setCurrentKudoRangeIndex(Number(event.target.value))
    }

    const onCloseKudoForm = () => {
        setMyKudoDialogOpen(false)
    };


    useEffect(() => {
        setCurrentUserKudoContents([])
        fetchMyKudos()
        return () => {
            setCurrentKudoRangeIndex(0)
            // Code to run when the component unmounts
            // For example, remove event listeners or perform cleanup tasks
        };
    }, [userProfile]);

    async function fetchMyKudos() {
        try {
            const response = await axios.get(`${API_URL}/groups/${userProfile.group_id}/user-kudos/?target_user_name=${userProfile.username}`);
            setCurrentUserKudoContents(response.data.data);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    }

    return (
        <>
            <Dialog open={isMyKudoDialogOpen}
                    sx={{}}>
                <DialogContent
                    sx={{
                        padding: '0px',
                        width: '500px',
                        border: 'solid 2px black',
                        borderRadius: 0,
                    }}>
                    <UserKudoDialogHeader onClick={onCloseKudoForm}
                                          username={viewingUserProfile.display_name}></UserKudoDialogHeader>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                        paddingTop: '16px',
                        paddingX: '24px',
                    }}>
                        <SwitchKudoHistoryButton
                            options={buildOptionsFromKudoContents(currentUserKudoContents)}
                            onChangeOption={onChangeKudoRange}
                        ></SwitchKudoHistoryButton>
                        <Box sx={{
                            overflow: 'auto',
                            overflowY: "scroll",
                            height: 'calc(100vh - 200px)',
                            position: 'relative',
                            '& ul': {padding: 0},
                            '&::-webkit-scrollbar': {display: 'none'}
                        }}>
                            <KudoHistoryBox
                                kudoContents={currentUserKudoContents.length !== 0 ? currentUserKudoContents[currentKudoRangeIndex].rows : []}></KudoHistoryBox>
                        </Box>

                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}

export function buildOptionsFromKudoContents(myKudoContentByRanges: MyKudoContentByRange[] | undefined): SelectOption[] {
    let options: SelectOption[] = []
    if (myKudoContentByRanges === undefined) {
        return options
    }
    for (let i = 0; i < myKudoContentByRanges.length; i++) {
        options.push({
            value: i.toString(),
            display_text: myKudoContentByRanges[i].name
        })
    }

    return options
}

export default UserKudoDialog;
