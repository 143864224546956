import React from 'react';
import {Grid} from "@mui/material";
import TotalCreditBox from "./total-credit-box";
import GiveKudoButton from "./give-kudo-button";
import {UserProfile, useUserProfiles} from "../shareable/user-profile";
import AspectRatio from '@mui/joy/AspectRatio';
import {useBetween} from "use-between";
import HoverableBox from "../cursor/hoverable-box";
import {useMyKudoDialog} from "../shareable/my-kudo-dialog";


interface ProfileCardProps {
    userProfile: UserProfile;
    isLoggedInUser: boolean;
}

const ProfileCard: React.FC<ProfileCardProps> = ({userProfile, isLoggedInUser = false}) => {
    const {setViewingUserProfile} = useBetween(useUserProfiles)
    const {setMyKudoDialogOpen} = useBetween(useMyKudoDialog)

    const openMyKudoDialog = () => {
        setMyKudoDialogOpen(true)
        setViewingUserProfile(userProfile)
    }
    return (
        <>
            <Grid container sx={{
                border: "2px solid black",
                width: '100%',
            }}>
                <Grid container sx={{
                    width: '100%',
                    borderBottom: "2px solid black",
                    backgroundColor: '#FECA42',
                }}
                >
                    <HoverableBox
                        sx={{
                            width: '100%',
                            height: '100%',
                            margin: '8px',
                        }}
                        onClick={() => openMyKudoDialog()}
                    >
                        <AspectRatio variant="plain"
                                     ratio={"4/3"}
                                     sx={{
                                         border: "2px solid black",
                                         backgroundColor: '#FECA42',
                                     }}
                        >
                            <img src={userProfile.avatar} style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                            }}>
                            </img>
                        </AspectRatio>
                    </HoverableBox>
                </Grid>
                <Grid item>
                    <Grid container sx={{
                        padding: '16px',
                    }}>
                        <Grid item xs={12}
                              sx={{
                                  fontSize: 28,
                                  textShadow: '2px 2px 0px #EE95AB',
                                  wordSpacing: '-5px !important'
                              }}>
                            {userProfile.display_name.toUpperCase()}
                        </Grid>
                        <Grid item xs={12}
                              sx={{
                                  fontSize: 16,
                              }}>
                            {userProfile.username}
                        </Grid>
                        <Grid container sx={{
                            width: '100%',
                            height: '48px',
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '12px'
                        }}>
                            <Grid sx={{
                                height: '100%',
                                flex: 1,
                                paddingRight: '12px',
                            }}>
                                <TotalCreditBox totalCredit={userProfile.received_coins}></TotalCreditBox>
                            </Grid>
                            <Grid sx={{
                                height: '100%',
                                width: '40%',
                            }}>
                                <GiveKudoButton enabled={!isLoggedInUser} targetUser={userProfile}></GiveKudoButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </>
    )
}

export default ProfileCard;