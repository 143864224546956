import {useState} from "react";
import {UserProfile} from "./user-profile";

export interface Leaderboard {
    id: number;
    name: string;
    rows: LeaderboardRowData[];
}

export interface LeaderboardRowData {
    received_coins: number
    user: UserProfile;
}

export const useLeaderboards = () => {
    const [leaderboards, setLeaderboards] = useState<Leaderboard[]>([]);
    return {
        leaderboards,
        setLeaderboards
    }
};