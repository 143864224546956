import React from 'react';
import {Box, Grid} from "@mui/material";
import ProfileBox from "./profile-box";
import {useBetween} from "use-between";
import {useGroupInfo} from "../shareable/group-info";
import {useUserProfiles} from "../shareable/user-profile";
import {BASE_PATH} from "../../setting/setting";

function KudoBoardHeader() {
    const {currentGroupInfo} = useBetween(useGroupInfo);
    const {myUserProfile} = useBetween(useUserProfiles)
    return (
        <>
            <Grid container sx={{
                width: '100%',
                height: '100%',
                borderBottom: "2px solid black",
                backgroundColor: '#FAE5B0',
                paddingLeft: '48px'
            }}>
                <Grid style={{
                    borderRight: "2px solid black",
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    flex: 1,
                }}>
                    <p style={{
                        fontSize: 32,
                    }}>{currentGroupInfo.name.toUpperCase()}</p>
                </Grid>
                <Grid item sx={{
                    borderRight: "2px solid black",
                    height: '100%',
                    alignSelf: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingLeft: '24px',
                    paddingRight: '24px',
                }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                        <Box sx={{
                            width: '40px',
                            height: '40px',
                            marginRight: '5px',
                            alignSelf: 'center'
                        }}>
                            <img src={BASE_PATH + '/images/credit-icon-2.png'} style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                            }}>

                            </img>
                        </Box>
                        <Box sx={{
                            fontSize: 28,
                            marginLeft: '6px',
                            alignSelf: 'center'
                        }}>
                            x{myUserProfile?.remaining_coins}
                        </Box>
                    </Box>
                </Grid>
                <Grid item sx={{
                    alignSelf: 'center',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '&:hover': {
                        backgroundColor: '#FECA42',
                    },
                    '&:active': {
                        backgroundColor: '#FECA42',
                    },
                }}>
                    <ProfileBox></ProfileBox>
                </Grid>
            </Grid>
        </>
    )
}

export default KudoBoardHeader;