import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {Alert, Box, Snackbar} from "@mui/material";
import KudoDialogHeader from "./kudo-dialog-header";
import KudoDialogCreditInput from "./kudo-dialog-credit-input";
import KudoDialogHashtagInput from "./kudo-dialog-hashtag-input";
import KudoDialogMessageInput from "./kudo-dialog-message-input";
import {useBetween} from "use-between";
import {useKudoFormState} from "../shareable/kudo-form";
import axios from "axios";
import {useGroupInfo} from "../shareable/group-info";
import HoverableBox from "../cursor/hoverable-box";
import {API_URL} from "../../setting/setting";

export default function KudoFormDialog() {
    const {
        kudoFormState, setKudoFormState,
        isKudoFormOpen, setIsKudoFormOpen
    } = useBetween(useKudoFormState)
    const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
    const [openSuccessSnackbar, setOpenSuccessSnackbar] = React.useState(false);
    const {currentGroupInfo} = useBetween(useGroupInfo)

    const onSubmitKudo = () => {
        executeKudo()
    };

    const handleCloseErrorSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenErrorSnackbar(false);
    };

    const handleCloseSuccessSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSuccessSnackbar(false);
    };

    const executeKudo = async () => {
        var kudoData = {
            message: kudoFormState.kudoMessage,
            target_user: kudoFormState.targetUser.username,
            hashtags: kudoFormState.hashtags,
            coin: kudoFormState.inputCreditValue
        }
        const response = await axios.post(`${API_URL}/groups/${currentGroupInfo.id}/kudos/`, kudoData);
        if (response.status === 200 && response.data.code === 0) {
            setIsKudoFormOpen(false)
            resetKudoFormState()
            setOpenSuccessSnackbar(true)
        } else {
            setOpenErrorSnackbar(true)
        }
    }

    const onCloseKudoForm = () => {
        setIsKudoFormOpen(false)
        resetKudoFormState()
    };

    const resetKudoFormState = () => {
        kudoFormState.kudoMessage = ""
        kudoFormState.hashtags = []
        kudoFormState.inputCreditValue = 0
        setKudoFormState(kudoFormState)
    }

    return (
        <>
            <Dialog open={isKudoFormOpen}>
                <Box sx={{
                    border: 'solid 2px black',
                    borderRadius: 0,
                }}>
                    <DialogContent
                        sx={{
                            padding: '0px',
                            width: '500px',
                        }}>
                        <KudoDialogHeader onClick={onCloseKudoForm}></KudoDialogHeader>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '16px',
                            paddingLeft: '40px',
                            paddingRight: '40px',
                            paddingTop: '26px',
                        }}>
                            <KudoDialogCreditInput></KudoDialogCreditInput>
                            <KudoDialogHashtagInput></KudoDialogHashtagInput>
                            <KudoDialogMessageInput></KudoDialogMessageInput>
                        </Box>

                    </DialogContent>
                    <DialogActions sx={{
                        padding: '0px',
                        paddingRight: '40px',
                        paddingTop: '16px',
                        paddingBottom: '24px',
                    }}
                    >
                        <HoverableBox>
                            <Button onClick={onSubmitKudo}
                                    sx={{
                                        fontSize: 16,
                                        fontFamily: 'VT323',
                                        height: '32px',
                                        width: '92px',
                                        backgroundColor: '#866AAE',
                                        color: 'white',
                                        border: 'solid 2px black',
                                        borderRadius: 0,
                                        boxShadow: '3px 4px 0px 0px rgba(0, 0, 0, 0.15)',
                                        '&:hover': {
                                            backgroundColor: '#A490C0',
                                        },
                                        '&:active': {
                                            backgroundColor: '#5A4972',
                                        },
                                    }}
                            >KUDO</Button>
                        </HoverableBox>
                    </DialogActions>
                </Box>
            </Dialog>
            <Snackbar open={openErrorSnackbar} autoHideDuration={6000} onClose={handleCloseErrorSnackbar}>
                <Alert onClose={handleCloseErrorSnackbar} severity="error" sx={{width: '100%'}}>
                    Có lỗi xảy ra!
                </Alert>
            </Snackbar>
            <Snackbar open={openSuccessSnackbar} autoHideDuration={6000} onClose={handleCloseSuccessSnackbar}>
                <Alert onClose={handleCloseSuccessSnackbar} severity="success" sx={{width: '100%'}}>
                    Kudo thành công!
                </Alert>
            </Snackbar>
        </>
    );
}