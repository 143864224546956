import React from 'react';
import {Box, Grid} from "@mui/material";
import KudoBoardHeader from "../kudo-board-header/kudo-board-header";
import ListProfile from "./list-profile";

function KudoBoard() {
    return (
        <>
            <Grid container sx={{}}>
                <Grid item sx={{
                    height: '64px',
                    backgroundColor: 'yellow',
                    width: '100%',
                }}>
                    <KudoBoardHeader></KudoBoardHeader>
                </Grid>
                <Box sx={{
                    height: 'calc(100vh - 112px)',
                    backgroundColor: '#FAE5B0',
                    width: '100%',
                    paddingTop: '48px',
                    paddingLeft: '48px',
                    paddingRight: '16px',
                    overflow: "auto",
                    overflowY: "scroll",
                    '&::-webkit-scrollbar': {display: 'none'}
                }}>
                    <ListProfile></ListProfile>
                </Box>
            </Grid>
        </>
    )
}

export default KudoBoard;