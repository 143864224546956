import React, {useEffect, useState} from 'react';

import KudoBoard from "../components/kudo-board/kudo-board";
import {Box, Grid} from "@mui/material";
import Leaderboard from "../components/leaderboard/leaderboard";
import InitShareable from "../components/shareable/init-shareable";
import KudoFormDialog from "../components/kudo-dialog/kudo-form-dialog";

import CustomCursor from "../components/cursor/custom-cursor";
import {capitalizeFirstLetter} from "../utils/utils";
import {UI_URL} from "../setting/setting";
import {useBetween} from "use-between";
import {useGroupInfo} from "../components/shareable/group-info";

function MainPage() {
    const [groupName, setGroupName] = useState<string>('')
    const {currentGroupInfo} = useBetween(useGroupInfo)
    const getGroupName = () => {
        let groupName = ''
        let regexStr = `${UI_URL}/(\\w+).*`.replaceAll('/', '\\\/')
        const match = window.location.href.match(regexStr);

        if (match) {
            groupName = match[1];
        }
        return groupName
    }
    useEffect(() => {
        let groupName = getGroupName()
        let displayGroupName = capitalizeFirstLetter(groupName)
        document.title = `${displayGroupName} Kudo Board`;
        setGroupName(groupName)
    }, []);
    return (
        <>
            <CustomCursor/>
            {(groupName !== '') &&
                <InitShareable currentGroupName={groupName}></InitShareable>}
            {(groupName !== '') && currentGroupInfo.name.toLowerCase() != groupName.toLowerCase() && <p style={{
                fontSize: '50px',
            }}>
                Phải xin phép vào group <b>{groupName}</b> trước bạn ơi!
            </p>}
            {(groupName !== '') && currentGroupInfo.name.toLowerCase() == groupName.toLowerCase() && <Box>
                <KudoFormDialog></KudoFormDialog>
                <Grid container>
                    <Grid item sx={{
                        backgroundColor: '#FAE5B0',
                        height: '100vh',
                        display: 'flex',
                        flex: 1,
                        borderRight: 'solid 2px black',
                    }}>
                        <KudoBoard></KudoBoard>
                    </Grid>
                    <Grid item sx={{width: '500px', height: '100vh'}}>
                        <Leaderboard></Leaderboard>
                    </Grid>
                </Grid>
            </Box>}
        </>
    )
}

export default MainPage;

