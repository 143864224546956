import React from 'react';
import {Box} from "@mui/material";
import {useBetween} from "use-between";
import {useUserProfiles} from "../shareable/user-profile";
import AspectRatio from "@mui/joy/AspectRatio";
import {useMyKudoDialog} from "../shareable/my-kudo-dialog";
import {BASE_PATH} from "../../setting/setting";
import HoverableBox from "../cursor/hoverable-box";

function ProfileBox() {
    const {myUserProfile, setViewingUserProfile} = useBetween(useUserProfiles)
    const {setMyKudoDialogOpen} = useBetween(useMyKudoDialog)

    const openMyKudoDialog = () => {
        setMyKudoDialogOpen(true)
        setViewingUserProfile(myUserProfile)
    }
    return (
        <>
            <HoverableBox sx={{
                display: 'flex',
                justifyContent: 'center',
                height: '100%',
            }}
                          onClick={() => openMyKudoDialog()}
            >

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '100%',
                    marginX: '24px',
                }}>
                    <AspectRatio variant="plain"
                                 ratio={"1"}
                                 sx={{
                                     width: '36px',
                                     height: '36px',
                                     alignSelf: 'center',
                                     border: "2px solid black",
                                 }}
                    >
                        <img src={myUserProfile?.avatar} style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                        }}>
                        </img>
                    </AspectRatio>
                    <Box sx={{
                        fontSize: 24,
                        alignSelf: 'center',
                        marginLeft: '12px',
                        marginRight: '12px',
                        wordSpacing: '-5px !important'
                    }}>
                        {myUserProfile?.display_name.toUpperCase()}
                    </Box>
                    <Box sx={{
                        height: '24px',
                        alignSelf: 'center',
                    }}>
                        <img src={BASE_PATH + "/images/profile-dropdown-icon.png"} style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                        }}>
                        </img>
                    </Box>
                </Box>
            </HoverableBox>
        </>
    )
}

export default ProfileBox;