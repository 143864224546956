import React, {useState} from "react";
import {UserProfile} from "./user-profile";


export interface KudoFormState {
    targetUser: UserProfile;
    inputCreditValue: number;
    hashtags: string[];
    kudoMessage: string;
}


export const useKudoFormState = () => {
    const [kudoFormState, setKudoFormState] = useState<KudoFormState>({
        targetUser: {
            id: 0,
            username: "",
            display_name: "",
            avatar: "",
            group_id: 0,
            remaining_coins: 0,
            received_coins: 0,
        },
        inputCreditValue: 0,
        hashtags: [],
        kudoMessage: "",
    });
    const [isKudoFormOpen, setIsKudoFormOpen] = useState<boolean>(false);
    return {
        kudoFormState,
        setKudoFormState,
        isKudoFormOpen,
        setIsKudoFormOpen
    }
};