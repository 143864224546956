import React, {useState} from "react";

export interface KudoContent {
    id: number,
    message: string,
    time_id: string,
    hashtags: string[],
    coin: number,
    target_user: string,
    username: string,
    created_at: number,
    avatar: string
}

export interface MyKudoContent {
    myKudoContentByRanges: MyKudoContentByRange[];
}

export interface MyKudoContentByRange {
    name: string;
    rows: KudoContent[];
}

export const useKudoContents = () => {
    const [kudoContents, setKudoContents] = useState<KudoContent[]>([]);
    const [myKudoContents, setMyKudoContents] = useState<MyKudoContentByRange[]>([]);
    return {
        kudoContents,
        setKudoContents,
        myKudoContents,
        setMyKudoContents,
    }
};