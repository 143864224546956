import React from 'react';
import {Box, Grid} from "@mui/material";
import {KudoContent} from "../shareable/kudo-content";
import AspectRatio from "@mui/joy/AspectRatio";
import {BASE_PATH} from "../../setting/setting";

interface KudoBoxProps {
    kudoContent: KudoContent;
}

const KudoBox: React.FC<KudoBoxProps> = ({kudoContent}) => {
    return (
        <>
            <Grid container sx={{
                width: '100%',
            }}>
                <Grid item xs={12}
                      sx={{
                          display: 'flex',
                          alignItems: 'center',
                          height: '48px',
                      }}>
                    <AspectRatio variant="plain"
                                 ratio={"1/1"}
                                 sx={{
                                     width: '44px',
                                     alignSelf: 'center',
                                     border: 'solid 2px black'
                                 }}
                    >
                        <img src={kudoContent.avatar} style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                        }}>
                        </img>
                    </AspectRatio>
                    <Grid container sx={{
                        paddingX: '11px',
                        height: '48px',
                    }}>
                        <Grid item xs={12} sx={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: 20,
                            height: '20px',
                        }}>
                            <p>+{kudoContent.coin}</p>
                            <Box sx={{
                                width: '20px',
                                height: '20px',
                                alignSelf: 'center',
                                paddingLeft: '4px',
                                flex: 1,
                            }}>
                                <img src={BASE_PATH + "/images/credit-icon-2.png"} style={{
                                    maxWidth: '100%',
                                    maxHeight: '100%',
                                }}>

                                </img>
                            </Box>
                            <p style={{
                                fontSize: 14,
                            }}>{timestampToDate(kudoContent.created_at)}</p>
                        </Grid>
                        <Grid item xs={12} sx={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: 20,
                            height: '20px',
                            wordSpacing: '-3px',
                        }}>
                            <span style={{
                                color: '#840E47',
                            }}>{kudoContent.username}</span>
                            <span>&nbsp;KUDO&nbsp;</span>
                            <span style={{
                                color: '#840E47',
                            }}>{kudoContent.target_user}</span>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    flexWrap: 'wrap',
                    marginTop: '12px',
                }}>
                    {kudoContent.hashtags.map(hashtag => (
                        <span style={{
                            paddingRight: '10px',
                            fontSize: 16,
                        }}>#{hashtag}</span>
                    ))}
                </Grid>
                <Grid sx={{
                    marginTop: '4px',
                }}>
                    <span style={{
                        fontSize: 18,
                    }}>{kudoContent.message}</span>
                </Grid>
            </Grid>

        </>
    )
}

const timestampToDate = (timestamp: number) => {
    let d = new Date(timestamp * 1000)
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
}

export default KudoBox;