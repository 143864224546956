import React from 'react';
import {Box, FormControl, Select, SelectChangeEvent} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {SelectOption} from "../../utils/utils";
import {BASE_PATH} from "../../setting/setting";
import HoverableBox from "../cursor/hoverable-box";

export const CustomArrowIcon = () => (
    <Box sx={{
        marginRight: '14px',
        display: 'flex',
    }}>
        <img src={BASE_PATH + "/images/select-dropdown-icon.png"} style={{
            height: '8px',
            width: '14px',
        }}>
        </img>
    </Box>
);

interface SwitchKudoHistoryButtonProps {
    options: SelectOption[];
    onChangeOption: (event: SelectChangeEvent) => void;
}

const SwitchKudoHistoryButton: React.FC<SwitchKudoHistoryButtonProps> = ({options, onChangeOption}) => {
    return (
        <>
            <HoverableBox>
                <FormControl fullWidth>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        defaultValue={'0'}
                        onChange={onChangeOption}
                        sx={{
                            fontSize: 16,
                            fontFamily: 'VT323',
                            border: 'solid 2px black',
                            borderRadius: 0,
                            height: '36px',
                            boxShadow: '2px 2px 0px 0px #000',
                            wordSpacing: '-4px',
                        }}
                        IconComponent={CustomArrowIcon}
                    >
                        {options.map(value => (
                            <MenuItem value={value.value}
                                      sx={{
                                          fontSize: 16,
                                          fontFamily: 'VT323',
                                          paddingLeft: '14px',
                                      }}
                            >{value.display_text}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </HoverableBox>
        </>
    )
}

export default SwitchKudoHistoryButton;