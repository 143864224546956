import React from 'react';
import {Box} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import KudoBox from "./kudo-box";
import {KudoContent} from "../shareable/kudo-content";


interface KudoHistoryBoxProps {
    kudoContents: KudoContent[];
}

const KudoHistoryBox: React.FC<KudoHistoryBoxProps> = ({kudoContents}) => {
    return (
        <>
            <List
                sx={{}}
                subheader={<li/>}
            >
                {kudoContents != null && kudoContents.map((kudoContent, index) => (
                    <Box sx={{}}
                    >
                        <ListItem key={kudoContent.id}
                                  sx={{
                                      padding: '0px',
                                      marginY: '16px',
                                  }}
                        >
                            <KudoBox kudoContent={kudoContent}></KudoBox>
                        </ListItem>
                        {index < kudoContents.length - 1 ? (<Divider variant="middle" sx={{
                            width: '100%',
                            border: 'solid 1px rgba(0, 0, 0, 0.10)',
                            margin: '0px',
                        }}></Divider>) : <></>}
                    </Box>
                ))}
            </List>
        </>
    )
}


export default KudoHistoryBox;