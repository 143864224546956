import React, {useState} from 'react';
import {Box, Grid} from "@mui/material";
import {useBetween} from "use-between";
import {useKudoFormState} from "../shareable/kudo-form";
import './kudo-dialog-credit-input.css';
import {BASE_PATH} from "../../setting/setting";
import {useUserProfiles} from "../shareable/user-profile";

const KudoDialogCreditInput = () => {
    const {kudoFormState, setKudoFormState} = useBetween(useKudoFormState)
    const [inputCreditValue, setInputCreditValue] = useState<number>(0)
    const [customInputCreditValue, setCustomInputCreditValue] = useState<number>(0)
    const [useCustomInput, setUseCustomInput] = useState<boolean>(false)
    const {myUserProfile} = useBetween(useUserProfiles)
    const defaultCreditValues = [1, 5, 10, 15, 20]
    const onDefaultInputBoxClick = (creditValue: number) => {
        kudoFormState.inputCreditValue = creditValue
        setKudoFormState(kudoFormState)
        setInputCreditValue(creditValue)
        setUseCustomInput(false)
    }
    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = Number(event.target.value)
        kudoFormState.inputCreditValue = inputValue
        setKudoFormState(kudoFormState)
        setInputCreditValue(inputValue)
        setCustomInputCreditValue(inputValue)
        setUseCustomInput(true)
    }
    const onClickCustomInput = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        kudoFormState.inputCreditValue = customInputCreditValue
        setKudoFormState(kudoFormState)
        setInputCreditValue(customInputCreditValue)
        setUseCustomInput(true)
    }
    return (
        <>
            <Grid container sx={{
                width: '100%',
                fontSize: 16,
            }}>
                <Grid item sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    height: '20px',
                    marginBottom: '8px',
                }}>
                    <p style={{
                        flex: 1,
                    }}>CREDIT</p>
                    <p>BALANCE</p>
                    <Box sx={{
                        width: '20px',
                        height: '20px',
                        alignSelf: 'center',
                        marginLeft: '8px',
                    }}>
                        <img src={BASE_PATH + "/images/credit-icon-2.png"} style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                        }}></img>
                    </Box>
                    <span style={{
                        marginLeft: '2px',
                    }}>x{myUserProfile?.remaining_coins}</span>
                </Grid>
                <Grid item sx={{
                    display: 'flex',
                    alignItems: 'stretch',
                    height: '32px',
                    width: '100%',
                    gap: '5px'
                }}>
                    {defaultCreditValues.map(creditValue =>
                        <Box sx={{
                            display: 'flex',
                            border: 'solid 2px black',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flex: 1,
                            backgroundColor: (!useCustomInput && creditValue === inputCreditValue) ? '#FECA42' : 'white',
                            boxShadow: (!useCustomInput && creditValue === inputCreditValue) ? '2px 2px 0px 0px rgba(0, 0, 0, 0.15)' : '',
                        }}
                             onClick={() => {
                                 onDefaultInputBoxClick(creditValue)
                             }}
                        >
                            {creditValue}
                        </Box>
                    )}
                    <input
                        type={"number"}
                        placeholder={"TUỲ TÂM"}
                        style={{
                            textAlign: 'center',
                            border: (useCustomInput && (customInputCreditValue <= 0 || customInputCreditValue > myUserProfile.remaining_coins))?'solid 2px #FF4D4F':'solid 2px black',
                            flex: 2,
                            backgroundColor: (useCustomInput) ? '#FECA42' : 'white',
                            fontSize: 16,
                            fontFamily: 'VT323',
                            boxShadow: (useCustomInput) ? '2px 2px 0px 0px rgba(0, 0, 0, 0.15)' : '',
                            width: '100%',
                            outline: '0px none transparent',
                        }}
                        className="no-spinner"
                        onChange={onInputChange}
                        onClick={onClickCustomInput}
                    />
                </Grid>
                <Grid item sx={{
                    height: '0px',
                    width: '100%',
                }}>
                    {(inputCreditValue > myUserProfile.remaining_coins) && <a style={{
                        fontSize: 12,
                        color: '#E43B44',
                    }}>NGHÈO MÀ CÒN RA DẼ</a>}
                </Grid>
            </Grid>
        </>
    )
}

export default KudoDialogCreditInput;