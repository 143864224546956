import React from 'react';
import {Box} from "@mui/material";
import {useBetween} from "use-between";
import {useKudoFormState} from "../shareable/kudo-form";
import {UserProfile} from "../shareable/user-profile";
import {BASE_PATH} from "../../setting/setting";
import HoverableBox from "../cursor/hoverable-box";

interface GiveKudoButtonProps {
    targetUser: UserProfile;
    enabled?: boolean
}

const GiveKudoButton: React.FC<GiveKudoButtonProps> = ({targetUser, enabled = true}) => {
    const {kudoFormState, setIsKudoFormOpen} = useBetween(useKudoFormState)
    const handleBoxClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        kudoFormState.targetUser = targetUser

        setIsKudoFormOpen(true)
    }

    return (
        <>
            <HoverableBox sx={{
                border: "2px solid black",
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: enabled ? '#FECA42' : 'rgba(0, 0, 0, 0.26)',
                boxShadow: '3px 3px 0px 0px rgba(0, 0, 0, 0.20)',
                '&:hover': enabled ? {
                    backgroundColor: '#FECA42', // New background color on hover
                } : {},
            }}
                          onClick={handleBoxClick}
                          enabled={enabled}
            >
                <Box style={{
                    height: '100%',
                    display: 'flex',
                    marginRight: '6px',
                }}>
                    <span style={{
                        alignSelf: 'center',
                        fontSize: 40,
                    }}>+</span>
                </Box>
                <Box style={{
                    height: '100%',
                    paddingLeft: '1px',
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <Box sx={{
                        height: '32px',
                        alignSelf: 'center',
                    }}>
                        <img src={BASE_PATH + "/images/credit-icon-2.png"} style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                        }}>
                        </img>
                    </Box>
                </Box>
            </HoverableBox>
        </>
    )
}

export default GiveKudoButton;