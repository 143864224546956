import * as React from 'react';
import {Box, Grid} from "@mui/material";
import {LeaderboardRowData} from "../shareable/leaderboard";
import AspectRatio from "@mui/joy/AspectRatio";
import {BASE_PATH} from "../../setting/setting";

interface LeaderboardTableRowProps {
    leaderboardRowData: LeaderboardRowData;
    rank: number;
}

const LeaderboardTableRow: React.FC<LeaderboardTableRowProps> = ({leaderboardRowData, rank}) => {
    return (
        <>
            <Grid container sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
            }}>
                <Grid style={{
                    width: '24px',
                    height: '24px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <p style={{
                        alignSelf: 'center',
                        fontSize: 24,
                    }}>{rank}</p>
                </Grid>
                <Grid sx={{
                    marginLeft: '24px',
                }}>
                    <AspectRatio variant="plain"
                                 ratio={"1"}
                                 sx={{
                                     width: '44px',
                                     height: '44px',
                                     alignSelf: 'center',
                                     border: "2px solid black",
                                 }}
                    >
                        <img src={leaderboardRowData.user.avatar} style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                        }}></img>
                    </AspectRatio>
                </Grid>
                <Grid style={{
                    flex: 1,
                    height: '48px',
                    display: 'flex',
                    justifyContent: 'left',
                    marginLeft: '16px',
                }}>
                    <p style={{
                        fontSize: 26,
                        alignSelf: 'center',
                        wordSpacing: '-5px'
                    }}>{leaderboardRowData.user.display_name}</p>
                </Grid>
                <Grid style={{
                    height: '100%',
                    marginRight: '7px',
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                    <p style={{
                        alignSelf: 'center',
                        fontSize: 36,
                    }}>{leaderboardRowData.received_coins}</p>
                </Grid>
                <Grid style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    marginRight: '13px',
                }}>
                    <Box sx={{
                        width: '40px',
                        height: '40px',
                        alignSelf: 'center',
                        display: 'flex',
                    }}>
                        <img src={BASE_PATH + "/images/credit-icon-2.png"} style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                        }}>
                        </img>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

export default LeaderboardTableRow;