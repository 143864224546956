import React, {useEffect, useState} from 'react';

import './custom-cursor.css';
import {BASE_PATH} from "../../setting/setting";
import {useBetween} from "use-between";
import {useCustomCursorState} from "../shareable/custom-cursor";


const CustomCursor = () => {
    const [position, setPosition] = useState({x: 0, y: 0});
    const {isHovered, isClicked} = useBetween(useCustomCursorState)

    const onMouseMove = (e: any) => {
        setPosition({x: e.clientX, y: e.clientY});
    };

    useEffect(() => {
        window.addEventListener('mousemove', onMouseMove);
        return () => {
            window.removeEventListener('mousemove', onMouseMove);
        };
    }, []);

    return (
        <img
            src={isClicked ? BASE_PATH + "/images/cursor-on-click.png" : isHovered ? BASE_PATH + "/images/cursor.png" : BASE_PATH + "/images/cursor.png"}
            alt="Custom Cursor"
            className="custom-cursor"
            style={{
                left: `${position.x}px`,
                top: `${position.y}px`,
                width: isClicked ? '63px' : '50px',
                height: isClicked ? '63px' : '50px',
                transform: isClicked ? 'translate(-16%, -5%)' : isHovered ? 'rotate(30deg) translate(29%, 0%)' : 'translate(0%, 0%)',
            }}
        />
    );
};

export default CustomCursor;