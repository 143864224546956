import React, {useState} from "react";


export interface CustomCursorState {
    isHovered: boolean
}

export const useCustomCursorState = () => {
    const [isHovered, setIsHovered] = useState<boolean>(false);
    const [isClicked, setIsClicked] = useState<boolean>(false);
    return {
        isHovered,
        setIsHovered,
        isClicked,
        setIsClicked,
    }
};