import React, {useState} from 'react';
import {Box, Grid} from "@mui/material";
import LeaderboardHeader from "./leaderboard-header";
import LeaderboardBox from "./leaderboard-box";
import KudHistoryBox from "../kudo-timeline/kudo-history-box";
import {useBetween} from "use-between";
import {useKudoContents} from "../shareable/kudo-content";

const Leaderboard = () => {
    const [showKudoHistory, setShowKudoHistory] = useState<boolean>(false);
    const [showLeaderboard, setShowLeaderboard] = useState<boolean>(true);
    const {kudoContents} = useBetween(useKudoContents)
    const onClickShowLeaderboard = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setShowLeaderboard(true)
        setShowKudoHistory(false)
    }
    const onClickShowKudoHistory = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setShowLeaderboard(false)
        setShowKudoHistory(true)
    }
    return (
        <>
            <Grid container>
                <Grid item sx={{height: '64px', backgroundColor: 'yellow', width: '100%'}}>
                    <LeaderboardHeader
                        onClickShowLeaderboard={onClickShowLeaderboard}
                        onClickShowKudoHistory={onClickShowKudoHistory}
                        showLeaderboard={showLeaderboard}
                        showKudoHistory={showKudoHistory}
                    ></LeaderboardHeader>
                </Grid>
                <Grid item sx={{
                    height: 'calc(100vh - 64px)',
                    backgroundColor: '#CFD3F2',
                    // backgroundColor: '#afb5e94a',
                    width: '100%',
                }}>
                    {showLeaderboard ? <>
                        <Box sx={{

                            overflow: 'auto',
                            overflowY: "scroll",
                            height: 'calc(100vh - 64px)',
                            position: 'relative',
                            '& ul': {padding: 0},
                            '&::-webkit-scrollbar': {display: 'none'}
                        }}>
                            <LeaderboardBox></LeaderboardBox>
                        </Box>
                    </> : <Box sx={{
                        paddingTop: '48px',
                        paddingX: '49px',
                        overflow: 'auto',
                        overflowY: "scroll",
                        height: 'calc(100vh - 112px)',
                        position: 'relative',
                        '& ul': {padding: 0},
                        '&::-webkit-scrollbar': {display: 'none'}
                    }}>
                        <KudHistoryBox kudoContents={kudoContents}></KudHistoryBox>
                    </Box>}

                </Grid>
            </Grid>
        </>
    )
}

export default Leaderboard;