import React from 'react';
import {Box, Grid} from "@mui/material";
import ProfileCard from "../profile-card/profile-card";
import {useBetween} from "use-between";
import {useUserProfiles} from "../shareable/user-profile";
import UserKudoDialog from "../kudo-dialog/user-kudo-dialog";

function ListProfile() {
    const {viewingUserProfile, userProfiles, myUserProfile} = useBetween(useUserProfiles);


    return (
        <>
            <Grid container sx={{
                width: '100%',
                height: '100%',
                backgroundColor: '#FAE5B0',
            }}>
                {userProfiles !== null && userProfiles.map(userProfile => (
                    <Grid item xs={4} sx={{
                        width: '100%',
                    }}>
                        <Box sx={{
                            paddingRight: '32px',
                            paddingBottom: '32px',
                        }}>
                            <ProfileCard isLoggedInUser={myUserProfile.username === userProfile.username}
                                         userProfile={userProfile}></ProfileCard>
                        </Box>
                    </Grid>
                ))}
            </Grid>
            <UserKudoDialog userProfile={viewingUserProfile}></UserKudoDialog>
        </>
    )
}

export default ListProfile;