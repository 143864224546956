import React from 'react';
import {Box, Grid} from "@mui/material";
import {BASE_PATH} from "../../setting/setting";
import HoverableBox from "../cursor/hoverable-box";

interface LeaderboardButtonProps {
    isPrimary: boolean,
    label: string,
    onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
}

const LeaderboardButton: React.FC<LeaderboardButtonProps> = ({isPrimary = true, label, onClick}) => {
    return (
        <>
            <HoverableBox sx={{
                height: '100%',
            }}>
                <Grid onClick={onClick}
                      style={{
                          height: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          flex: 1,
                          paddingLeft: (label === 'LEADERBOARD') ? '30px' : '0px',
                          paddingRight: (label === 'LEADERBOARD') ? '0px' : '30px',
                          fontSize: 24,
                          flexDirection: (label === 'LEADERBOARD') ? 'row' : 'row-reverse',
                      }}
                >
                    {isPrimary ? <>
                        <Box sx={{
                            width: '14px',
                            height: '14px',
                            display: 'flex',
                        }}>
                            <img src={BASE_PATH + "/images/leaderboard-header-decorator.png"} style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                            }}>
                            </img>
                        </Box>
                        <p style={{
                            marginLeft: '8px',
                            marginRight: '8px',
                        }}
                        >{label}</p>
                        <Box sx={{
                            width: '14px',
                            height: '14px',
                            display: 'flex',
                        }}>
                            <img src={BASE_PATH + "/images/leaderboard-header-decorator.png"} style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                            }}>
                            </img>
                        </Box>
                    </> : <>
                        <p style={{
                            opacity: '45%',
                            marginLeft: '22px',
                            marginRight: '22px',
                        }}>{label}</p>
                    </>}


                </Grid>
            </HoverableBox>
        </>
    )
}

export default LeaderboardButton;