import React from 'react';
import {Grid} from "@mui/material";
import LeaderboardButton from "./leaderboard-button";

interface LeaderboardHeaderProps {
    showLeaderboard?: boolean;
    showKudoHistory?: boolean;
    onClickShowLeaderboard: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onClickShowKudoHistory: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const LeaderboardHeader: React.FC<LeaderboardHeaderProps> = (
    {
        showLeaderboard = true,
        showKudoHistory = false,
        onClickShowLeaderboard,
        onClickShowKudoHistory,
    }) => {
    return (
        <>
            <Grid container sx={{
                width: '100%',
                height: '100%',
                borderBottom: "2px solid black",
                backgroundColor: '#C2AFE9',
                paddingLeft: '53px',
                paddingRight: '53px',
            }}>
                <Grid xs={6} style={{
                    height: '100%',
                }}>
                    <LeaderboardButton
                        isPrimary={showLeaderboard}
                        label={"LEADERBOARD"}
                        onClick={onClickShowLeaderboard}
                    ></LeaderboardButton>
                </Grid>
                <Grid xs={6} style={{
                    height: '100%',
                    wordSpacing: '-5px',
                }}>
                    <LeaderboardButton
                        isPrimary={showKudoHistory}
                        label={"LATEST KUDO"}
                        onClick={onClickShowKudoHistory}
                    ></LeaderboardButton>
                </Grid>
            </Grid>
        </>
    )
}

export default LeaderboardHeader;