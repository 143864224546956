import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import LeaderboardTableRow from "./leaderboard-table-row";
import Divider from "@mui/material/Divider";
import {Leaderboard} from "../shareable/leaderboard";

interface LeaderboardTableProps {
    currentLeaderboardIndex: number;
    leaderboards: Leaderboard[];
}

const LeaderboardTable: React.FC<LeaderboardTableProps> = ({currentLeaderboardIndex = 0, leaderboards}) => {
    return (
        <List
            sx={{
                width: '100%',
                position: 'relative',
            }}
            subheader={<li/>}
        >
            {leaderboards.length !== 0 && leaderboards[currentLeaderboardIndex].rows != null && leaderboards[currentLeaderboardIndex].rows.map((rowData, index) => (
                <ul>
                    <ListItem key={`item-${index}`}
                              sx={{
                                  height: '72px',
                                  paddingX: '0px',
                              }}
                    >
                        <LeaderboardTableRow leaderboardRowData={rowData}
                                             rank={index + 1}></LeaderboardTableRow>
                    </ListItem>
                    {index < leaderboards[currentLeaderboardIndex].rows.length - 1 ? (<Divider variant="middle" sx={{
                        width: '100%',
                        border: 'solid 1px rgba(0, 0, 0, 0.10)',
                        marginX: '0px',
                    }}></Divider>) : <></>}
                </ul>
            ))}
        </List>
    );
}

export default LeaderboardTable;