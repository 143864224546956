import React, {useState} from "react";

export interface GroupInfo {
    id: number;
    name: string;
}

export const useGroupInfo = () => {
    const [currentGroupInfo, setCurrentGroupInfo] = useState<GroupInfo>({id: 0, name: "unknown"});
    const [groupInfos, setGroupInfos] = useState<GroupInfo[]>([]);
    return {
        currentGroupInfo,
        setCurrentGroupInfo,
        groupInfos,
        setGroupInfos
    }
};