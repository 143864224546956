import React, {useState} from 'react';
import {Grid} from "@mui/material";
import {useBetween} from "use-between";
import {useKudoFormState} from "../shareable/kudo-form";

const KudoDialogMessageInput = () => {
    const {kudoFormState, setKudoFormState} = useBetween(useKudoFormState)
    const [currentMessage, setCurrentMessage] = useState<string>("")
    const onInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        kudoFormState.kudoMessage = event.target.value
        setKudoFormState(kudoFormState)
        setCurrentMessage(event.target.value)
    }
    return (
        <>
            <Grid container sx={{
                width: '100%',
            }}>
                <Grid item sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    height: '20px',
                    marginBottom: '8px',
                }}>
                    <p style={{
                        flex: 1,
                        fontSize: 16,
                    }}>MESSAGE</p>
                </Grid>
                <Grid item sx={{
                    display: 'flex',
                    alignItems: 'stretch',
                    width: '100%',
                    gap: '5px',
                    flexWrap: 'wrap',
                }}>
                    <textarea
                        placeholder={"Điền vô đi bạn"}
                        style={{
                            width: '100%',
                            height: '120px',
                            fontSize: 18,
                            fontFamily: 'VT323',
                            border: currentMessage !== "" ? 'solid 2px black' : 'solid 2px #FF4D4F',
                            resize: 'none',
                            outline:'0px none transparent'
                        }}
                        onChange={onInputChange}
                    ></textarea>

                </Grid>
                <Grid item sx={{
                    height: '20px',
                    width: '100%',
                }}>
                    {(currentMessage === "") && <a style={{
                        fontSize: 12,
                        color: '#E43B44',
                    }}>↑↑↑ VIỆC HÔM NAY CHỚ ĐỂ NGÀY MAI</a>}
                </Grid>
            </Grid>
        </>
    )
}

export default KudoDialogMessageInput;