// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    cursor: none !important;
}

.custom-cursor {
    position: fixed;
    z-index: 9999;
    cursor: none;
    pointer-events: none;
    transition: transform 0.07s ease;
}`, "",{"version":3,"sources":["webpack://./src/components/cursor/custom-cursor.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,aAAa;IACb,YAAY;IACZ,oBAAoB;IACpB,gCAAgC;AACpC","sourcesContent":["* {\n    cursor: none !important;\n}\n\n.custom-cursor {\n    position: fixed;\n    z-index: 9999;\n    cursor: none;\n    pointer-events: none;\n    transition: transform 0.07s ease;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
